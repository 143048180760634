<template>
  <v-container fluid>
    <v-row class="mb-n5">
      <v-col cols="12" md="4" lg="3">
        <DatePicker v-if="!alertsLoader" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="alerts"
          :loading="alertsLoader"
          :item-class="alertUnseenStyle"
          :items-per-page="15"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>
                <v-switch
                  v-model="viewAll"
                  :label="viewAll ? 'Toutes les alertes' : 'Alertes non-lues sur la plage de dates sélectionnée'"
                  class="mt-6 ml-3"
                  @change="switchSeen()"
                ></v-switch>
              </v-toolbar-title>
              <v-spacer />
              <Export v-if="alerts.length > 0" :exportedAlerts="alerts" />
            </v-toolbar>
          </template>

          <!-- ------------ -->
          <!-- HEADER ITEMS -->
          <!-- ------------ -->
          <template v-slot:[`header.kind`]="{ header }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ header.text }}</span>
              </template>
              <span
                >Identifiant d'hôte de caisse, numéro de carte de fidélité,
                montant unitaire ou numéro de code opération / code
                article</span
              >
            </v-tooltip>
          </template>

          <template v-slot:[`header.value`]="{ header }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ header.text }}</span>
              </template>
              <span>Peut être un montant ou un total</span>
            </v-tooltip>
          </template>

          <template v-slot:[`header.triggered_financial_risk`]="{ header }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ header.text }}</span>
              </template>
              <span
                >valeur positive si crédits / négative si débits / absolue si
                débits et crédits</span
              >
            </v-tooltip>
          </template>

          <template v-slot:[`header.triggered_threshold`]="{ header }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ header.text }}</span>
              </template>
              <span>Seuil de déclenchement de l'alerte</span>
            </v-tooltip>
          </template>

          <!-- ---------- -->
          <!-- ROWS ITEMS -->
          <!-- ---------- -->
          <template
            v-slot:[`item.date`]="{ item }"
            style="border-left: 5px solid orange"
          >
            <span>{{ item.formatted.date }}</span>
          </template>

          <template v-slot:[`item.rule_id`]="{ item }">
            <span>
              {{ item.rule.name }}
            </span>
          </template>

          <template v-slot:[`item.kind`]="{ item }">
            <v-chip
              :color="item.seen ? '' : 'secondary'"
              :outlined="item.seen ? true : false"
            >
              <v-icon left>mdi-{{ icons[item.kind] }}</v-icon>
              <v-divider vertical></v-divider>
              <span class="ml-2">{{ item.key }}</span>
            </v-chip>
          </template>

          <template v-slot:[`item.triggered_value`]="{ item }">
            <span>{{ item.formatted.triggered_value }}</span>
            <v-tooltip
              v-if="item.value_threshold"
              bottom
              color="rgba(0, 0, 0, 0.9)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon right v-bind="attrs" v-on="on">
                  mdi-help-circle
                </v-icon>
              </template>
              <span>Risque max accepté :</span>
              {{ item.formatted.value_threshold }}
            </v-tooltip>
          </template>

          <template v-slot:[`item.triggered_financial_risk`]="{ item }">
            <span>{{ item.formatted.triggered_financial_risk }}</span>
          </template>

          <template v-slot:[`item.triggered_threshold`]="{ item }">
            <span
              >{{ item.triggered_duration }}
              {{ $t("day") }}</span
            >
          </template>

          <template v-slot:[`item.feedback`]="{ item }">
            <Relevance :alertData="item" />
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <router-link
              :to="{ path: '/alerts/' + item.id, params: { alertId: item.id } }"
              style="text-decoration: none"
            >
              <v-icon>mdi-magnify</v-icon>
            </router-link>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { formatAlerts } from "../services/alert"

export default {
  components: {
    DatePicker: () => import("@/components/Alerts/DatePicker"),
    Export: () => import("@/components/Exports/ExportAlerts"),
    Relevance: () => import("@/components/Alerts/Relevance"),
  },
  data: () => ({
    viewAll: true,
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("date"),
          value: "date",
          sortable: true,
        },
        { text: this.$t("type"), value: "rule_id", sortable: false },
        {
          text: this.$t("origin"),
          value: "kind",
        },
        {
          text: this.$t("value"),
          value: "triggered_value",
          align: "right",
          width: "130",
        },
        {
          text: this.$t("financialRisk"),
          value: "triggered_financial_risk",
          align: "center",
          width: "95",
        },
        {
          text: this.$t("threshold"),
          value: "triggered_threshold",
          sortable: false,
          align: "center",
          width: "120",
        },
        {
          text: this.$t("feedback"),
          value: "feedback",
          sortable: false,
          align: "center",
          width: "80",
        },
        {
          text: this.$t("detail"),
          value: "actions",
          sortable: false,
          align: "right",
          width: "20",
        },
      ];
    },
    ...mapState({
      alerts: (state) => formatAlerts(state.alerts.alerts, { queries: state.rules.rulesNames }),
      alertsLoader: (state) => state.alerts.alertsLoader,
      rules: (state) => state.rules.rulesNames,
      icons: (state) => state.alerts.alertsIcons,
    }),
  },
  methods: {
    alertUnseenStyle: function (item) {
      return item.seen ? null : "unseen";
    },
    switchSeen: function () {
      const unseenFilter = this.viewAll ? undefined : false;

      this.$store.dispatch("alerts/filterUnseen", unseenFilter);
    },
  },
  async created() {
    await this.$store.dispatch("rules/loadRulesNames");
    this.$store.dispatch("alerts/loadAlerts");
  },
};
</script>

<style>
.unseen > td:first-child {
  border-left: 5px solid #f28c00;
}
</style>
