var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-n5"},[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"3"}},[(!_vm.alertsLoader)?_c('DatePicker'):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.alerts,"loading":_vm.alertsLoader,"item-class":_vm.alertUnseenStyle,"items-per-page":15},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('v-switch',{staticClass:"mt-6 ml-3",attrs:{"label":_vm.viewAll ? 'Toutes les alertes' : 'Alertes non-lues sur la plage de dates sélectionnée'},on:{"change":function($event){return _vm.switchSeen()}},model:{value:(_vm.viewAll),callback:function ($$v) {_vm.viewAll=$$v},expression:"viewAll"}})],1),_c('v-spacer'),(_vm.alerts.length > 0)?_c('Export',{attrs:{"exportedAlerts":_vm.alerts}}):_vm._e()],1)]},proxy:true},{key:"header.kind",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("Identifiant d'hôte de caisse, numéro de carte de fidélité, montant unitaire ou numéro de code opération / code article")])])]}},{key:"header.value",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("Peut être un montant ou un total")])])]}},{key:"header.triggered_financial_risk",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("valeur positive si crédits / négative si débits / absolue si débits et crédits")])])]}},{key:"header.triggered_threshold",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("Seuil de déclenchement de l'alerte")])])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.formatted.date))])]}},{key:"item.rule_id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.rule.name)+" ")])]}},{key:"item.kind",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.seen ? '' : 'secondary',"outlined":item.seen ? true : false}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-"+_vm._s(_vm.icons[item.kind]))]),_c('v-divider',{attrs:{"vertical":""}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.key))])],1)]}},{key:"item.triggered_value",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.formatted.triggered_value))]),(item.value_threshold)?_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0, 0, 0, 0.9)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle ")])]}}],null,true)},[_c('span',[_vm._v("Risque max accepté :")]),_vm._v(" "+_vm._s(item.formatted.value_threshold)+" ")]):_vm._e()]}},{key:"item.triggered_financial_risk",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.formatted.triggered_financial_risk))])]}},{key:"item.triggered_threshold",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.triggered_duration)+" "+_vm._s(_vm.$t("day")))])]}},{key:"item.feedback",fn:function(ref){
var item = ref.item;
return [_c('Relevance',{attrs:{"alertData":item}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ path: '/alerts/' + item.id, params: { alertId: item.id } }}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }